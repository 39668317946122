<template>
  <div class="admin-page">
    <section :class="isAuthenticated ? '' : 'top-section'">
      <h2 class="is-size-2">Solvangsenteret</h2>
      <h3 class="is-size-3">Administrere beboere</h3>
    </section>
    <section v-if="isAuthenticated">
      <p class="is-size-5">Her kan du endre navn på beboerlista</p>
      <div class="inline-edit-list">
        <div
          class="inline-edit-list-row"
          v-for="(beboer, index) of beboere"
          :key="index"
        >
          <input
            class="input is-medium"
            type="text"
            :value="beboer"
            @change="updateItem(index, $event.target.value)"
            @input="debouncedUpdateItem(index, $event.target.value)"
            placeholder="Medium input"
          />
        </div>
      </div>
      <div class="buttons" v-if="showButtonGroup">
        <button class="button" :disabled="disableButtons" @click="abort">
          Avbryt
        </button>
        <button
          class="button is-primary"
          :disabled="disableButtons"
          @click="save"
        >
          Lagre
        </button>
      </div>
      <div v-else class="buttons">
        <article class="message is-success" style="padding: 1rem">
          <b>Endringer lagret!</b> Siden lastes på nytt om noen sekunder...
        </article>
      </div>
    </section>
    <section v-else id="pin-container">
      <p class="is-size-5" style="margin-top: 1rem">Skriv inn PIN-koden</p>
      <input
        id="pin"
        class="input is-large"
        type="number"
        placeholder="PIN"
        v-model="pinAttempt"
        min="0"
        max="9999"
        @keydown.enter="tryLogIn"
      />
      <button class="button is-info is-large" @click="tryLogIn">
        LOGG INN
      </button>
      <article
        v-if="wrongPIN === true"
        class="message is-danger"
        style="padding: 1rem"
      >
        <b>Feil PIN-kode!</b> Prøv igjen...
      </article>
    </section>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { initializeApp } from "firebase/app";
import { getDatabase, ref as firebaseRef, get, set } from "firebase/database";

const debounce = (func, wait) => {
  var timeout;

  return function () {
    var context = this;
    var args = arguments;

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      func.apply(context, args);
    }, wait);
  };
};

const firebaseConfig = {
  apiKey: "AIzaSyBf9wN-wro7KT0spk5hGZt9YZ5lEDWCC1E",
  authDomain: "beboerlista.firebaseapp.com",
  databaseURL:
    "https://beboerlista-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "beboerlista",
  storageBucket: "beboerlista.appspot.com",
  messagingSenderId: "1032512969695",
  appId: "1:1032512969695:web:deae4de8ef4be49451426a",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const dataRef = firebaseRef(database, "beboerliste");
const originalBeboere = ref([]);
const beboere = ref([]);
const firebaseData = ref(null);
const disableButtons = ref(true);
const showButtonGroup = ref(true);
const isAuthenticated = ref(false);
const wrongPIN = ref(undefined);
const pinAttempt = ref(null);
const CORRECT_PIN = 7575;

const tryLogIn = () => {
  if (pinAttempt.value === CORRECT_PIN) {
    isAuthenticated.value = true;
    wrongPIN.value = false;
  } else {
    isAuthenticated.value = false;
    wrongPIN.value = true;
    setTimeout(() => {
      wrongPIN.value = false;
    }, 3000);
  }
};

const updateItem = (index, newValue) => {
  const clonedBeboere = [...beboere.value];
  clonedBeboere[index] = newValue;
  beboere.value = clonedBeboere;
  const listHasChanges = isDiff(originalBeboere.value, beboere.value);
  disableButtons.value = !listHasChanges;
};

const debouncedUpdateItem = debounce(updateItem, 500);

const abort = () => {
  beboere.value = originalBeboere.value;
  disableButtons.value = true;
};

const save = async () => {
  try {
    console.warn("saving");
    firebaseData.value.solvangsenteret.beboerliste = beboere.value;
    await set(dataRef, firebaseData.value);
    console.warn("data updated successfully");
    showButtonGroup.value = false;
    setTimeout(() => {
      load();
      showButtonGroup.value = true;
    }, 3000);
    // reload page
  } catch (err) {
    console.error("error updating data", err);
  }
};

const isDiff = (listA, listB) => {
  return JSON.stringify(listA) !== JSON.stringify(listB);
};

const load = async () => {
  try {
    const snapshot = await get(dataRef);
    if (snapshot.exists()) {
      firebaseData.value = snapshot.val();
      beboere.value = firebaseData.value.solvangsenteret.beboerliste;
      originalBeboere.value = firebaseData.value.solvangsenteret.beboerliste;
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error("Error reading data from Firebase:", error);
  }
};

onMounted(async () => {
  load();
});
</script>

<style lang="scss">
.top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#pin-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
#pin {
  width: 10rem;
  // margin-top: 1rem;
  // font-size: 2rem;
  text-align: center;
}
.buttons {
  margin-top: 1rem;
  width: calc(100vh - 1rem);
  max-width: 28rem;
  display: flex;
  justify-content: space-evenly;
}
.admin-page {
  height: 100vh;
  padding: 0.5rem;
  font-size: 3rem;
}

.inline-edit-list {
  margin-top: 1rem;
  width: calc(100vh - 1rem);
  max-width: 30rem;
  height: calc(95vh - 20rem);
  overflow-y: auto;
  border: 2px solid #ccc;
  padding: 0.25rem;
  padding-left: 0.35rem;

  .input {
    max-width: 25rem;
    margin-top: 0.25rem;
  }
}

.inline-edit-list-row {
  display: flex;
  flex-direction: row;
}
</style>
