<template>
  <div class="page">
    <h1 class="is-size-2" @click="updateScript">
      Beboerliste - Solvangsenteret
    </h1>
    <div class="card-container" :class="isHorizontal ? '' : 'vertical'">
      <div
        class="beboer-card"
        v-for="(beboer, index) of beboere"
        :key="index"
        :class="isHorizontal ? '' : 'vertical'"
      >
        {{ beboer }}
      </div>
    </div>
  </div>
  <!-- <div>
    <h1 class="is-size-1">Beboerlista</h1>
    <div v-if="firebaseData">
      <h2>Data from Firebase:</h2>
      <pre>{{ firebaseData }}</pre>
    </div>
    <div>
      <h2>Update Firebase Data:</h2>
      <button @click="update">Update</button>
    </div>
  </div> -->
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { initializeApp } from "firebase/app";
import { getDatabase, ref as firebaseRef, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBf9wN-wro7KT0spk5hGZt9YZ5lEDWCC1E",
  authDomain: "beboerlista.firebaseapp.com",
  databaseURL:
    "https://beboerlista-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "beboerlista",
  storageBucket: "beboerlista.appspot.com",
  messagingSenderId: "1032512969695",
  appId: "1:1032512969695:web:deae4de8ef4be49451426a",
};

const app = initializeApp(firebaseConfig);

const database = getDatabase(app);
const dataRef = firebaseRef(database, "beboerliste");

const beboere = ref([]);

const firebaseData = ref(null);

const isHorizontal = computed(() => {
  // Get the screen width and height
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  const screenHeight =
    window.innerHeight || document.documentElement.clientHeight;

  // Check if width is larger than height
  return screenWidth > screenHeight;
});

const serializer = (data) => {
  if (data.length === 0) {
    return [...data, ...["Beboer 1", "Beboer 2", "Beboer 3", "Beboer 4"]];
  } else if (data.length === 1) {
    return [...data, ...["", "", ""]];
  } else if (data.length === 2) {
    return [...data, ...["", ""]];
  } else if (data.length === 3) {
    return [...data, ...[""]];
  } else {
    return data;
  }
};

const load = async () => {
  try {
    const snapshot = await get(dataRef);
    if (snapshot.exists()) {
      firebaseData.value = snapshot.val();
      beboere.value = serializer(
        firebaseData.value.solvangsenteret.beboerliste
      );

      console.log(
        "firebaseData.value.solvangsenteret.beboerliste :>> ",
        firebaseData.value.solvangsenteret.beboerliste
      );
      console.warn(firebaseData.value);
    } else {
      console.log("No data available");
    }
  } catch (error) {
    console.error("Error reading data from Firebase:", error);
  }
};

onMounted(async () => {
  load();
});

// update page every 60 minutes
const updateInterval = 60 * 60 * 1000;
setInterval(() => {
  load();
}, updateInterval);

// use this for updating data in Firebase
// then add "set" in import above: "firebase/database";
const updateScript = async () => {
  // try {
  //   firebaseData.value = {
  //     solvangsenteret: {
  //       admin: "solvangsenteret.beboerlista.no/admin",
  //       beboerliste: [
  //         "101 - Senterkontor/Resepsjon",
  //         "102 - Kontor",
  //         "103 - TomTommesen",
  //         "104 - Tom",
  //         "105 - Tom Tommesen/Eva Tommesen sensensen",
  //         "106- Tom Tommesen",
  //         "107- Tom Tommesen",
  //         "108- Fellesstue 1 etg.",
  //         "109- Hjemmetjenesten",
  //         "110- Solstua",
  //         "111- Kjøkken",
  //         "112 - Kafeteria",
  //         "113- Aktivitetsrom/Systue",
  //         "114- Svein Gunnar Kjerte",
  //         "115- Terje Moving",
  //         "116- Sandefjord Kommune",
  //         "117- Fotpleier/Frisør",
  //         "201- Wilfred Larsen",
  //         "202- Kjell Holtedal",
  //         "203 - Aase Bratsberg",
  //         "204 - Tom Tommesen",
  //         "205 - Tom Tommesen",
  //         "206 - Gerd Johanne Klavenes",
  //         "207 - Aura Enelia Mejlænder",
  //         "208 - Inger Lise Kjær",
  //         "209 - Fellesstue 2 etg.",
  //         "210 - Reidun Carlsen",
  //         "211 - Rolf Olsen",
  //         "212 - Axel Olsen",
  //         "213 - Anette Olsen",
  //         "214 - Roger Fredriksen",
  //         "215 - Tom Tommesen",
  //         "216 - Kjell Robert Larsen",
  //         "217 - Lise Catrine Haakestad",
  //         "218 - Bjørn Strid",
  //         "219 - Nadiia Bidenko",
  //         "301 - Else Marie Andersen",
  //         "302 - Jorunn Gibson",
  //         "303 - Anne-Lise Ellefsen",
  //         "304 - Johann Larsen",
  //         "305 - Kjell Robert Larsen",
  //         "306 - Tom Tommesen",
  //         "307 - Anne Lise Kjellstrøm",
  //         "308 - Marit Larsen",
  //         "309 - Fellesstue 3 etg.",
  //         "310 - Tom Tommesen",
  //         "311 - Liv Holbæk",
  //         "312 - Anne Lise Birkeland",
  //         "313 - Anne Lise Fjellheim",
  //         "314 - Yevheniia Vladymirets",
  //         "315 - Tore Olav Olsen",
  //         "316 - ",
  //         "317 - Harry Åshildrød",
  //         "318 - Inger Karin Hagen",
  //         "319  - Anne-Lise Jahre",
  //         "401 - Ellen Gro Johansen",
  //         "402 - Bjørn Raymond Alstermo",
  //         "403 - Magnhild Bergliot Hansen",
  //         "404 - ",
  //         "405 - Tom Tommesen",
  //         "406 - Fatemeh Bahrami",
  //         "407 - Bjørnar Fagerholt",
  //         "408 - Jorunn Rogers",
  //         "409 - Fellesstue 4 etg",
  //         "410 - Eva Johansen",
  //         "411 - Tom Tommesen",
  //         "412 - Tom Tommesen",
  //         "413 - Tom Tommesen",
  //         "414 - Bjørn Helge Leite",
  //         "415 - Aase Boye Hansen",
  //         "416 - Helge Johansen",
  //         "417 - Oddny Gustavsen",
  //         "501 - Tom Tommesen",
  //         "502  - Erling Eskil Græsman",
  //         "503 - Gunlaug Buøen",
  //         "504 - Eva Synnøve Antonsen",
  //         "505 - Borgny Myrland Rismyhr",
  //         "506 - Reidun Jacobsen",
  //         "507 - Espen Prøitz",
  //         "508 - Unni Solvang",
  //         "509 - Fellesstue 5 etg.",
  //         "510 - Einar Midttun",
  //         "511 - Nida Frantzen",
  //         "512 - Erik Line",
  //         "513 - Atle Barmen",
  //         "514 - Jan Egil Ulleberg",
  //         "515 - Eva Holm",
  //         "601 - Fellesstue 6 etg.",
  //         "602 - Erik Kleiven",
  //         "603 - Tom Hansen",
  //         "604 - Jerzy Adam Macha",
  //         "605 - ",
  //         "606 - Bjørn Strid",
  //       ],
  //       domene: "solvangsenteret.beboerlista.no",
  //       navn: "Solvangsenteret",
  //     },
  //     demo: {
  //       navn: "Demo",
  //       domene: "demo.beboerlista.no",
  //       admin: "demo.beboerlista.no/admin",
  //       beboerliste: ["H0101 - Ola Nordmann", "H0102 - Kari Nordmann"],
  //     },
  //   };
  //   await set(dataRef, firebaseData.value);
  //   console.log("Data updated successfully!");
  // } catch (error) {
  //   console.error("Error updating data in Firebase:", error);
  // }
};
</script>

<style lang="scss">
.page {
  padding: 1rem;
  padding-right: 0rem;
  padding-left: 0.75rem;
  max-height: 100vh;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 0.2rem;
  width: calc(100vw - 4rem);
  height: calc(100vh - 7rem);
  margin-left: 0rem;
  &.vertical {
    margin-top: 1rem;
    margin-left: 0.2rem;
  }
}

$font-size: 1.375rem;

.beboer-card {
  width: 20%;
  margin-bottom: 0px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  height: 4.4vh;

  font-size: $font-size;
  display: flex;
  justify-content: flex-start;
  padding-left: 0.5rem;
  align-items: center;
  background-color: #fff;
  color: #000;

  // if text overflows, wrap text to next row
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  line-height: $font-size;

  &.vertical {
    width: calc(33.8%);
    height: 2.86vh;
  }
}
</style>
